<template>
	<div class="organList">
		<el-card>
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="机构名称">
					<el-input class="w300" v-model="searchForm.serviceName" placeholder="请输入机构名称" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doSearch">搜索</el-button>
				</el-form-item>
			</el-form>
			<el-table style="width: 100%" :data="tableData" border :header-cell-style="headerCellStyle" v-loading="loading">
				<template slot="empty">
					<IsEmpty />
				</template>
				<el-table-column prop="serviceName" label="机构名称" align="center" />
				<el-table-column prop="createTime" label="创建日期" align="center" />
				<el-table-column prop="serviceContact" label="联系人" align="center" />
				<el-table-column prop="serviceContactInformation" label="联系方式" align="center" />
				<el-table-column prop="serviceScope" label="管辖范围" align="center" width="350" :show-overflow-tooltip="true" />
				<el-table-column prop="address" label="地址" align="center" />
				<el-table-column prop="prop" label="操作" min-width="100" align="center">
					<template slot-scope="scope">
						<el-button type="text" @click="seePeople(scope.row)">查看人员</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				background
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="tableParams.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="tableParams.pageSize"
				layout="total, sizes, prev, pager, next"
				:total="total"
			>
			</el-pagination>
		</el-card>
		<!-- 查看人员弹窗 -->
		<OrganMember v-if="dialogVisible" :dialogVisible.sync="dialogVisible" :organId="organId" />
	</div>
</template>

<script>
import OrganMember from './organMember.vue'
import { organPage } from '@/api/organManage'
export default {
	// 机构列表
	name: 'organList',
	components: {
		OrganMember,
	},
	data() {
		return {
			loading: false,
			dialogVisible: false,
			searchForm: {
				serviceName: '',
			},
			tableData: [
				{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
			],
			tableParams: {
				pageSize: 10,
				pageNum: 1,
			},
			total: 0,
			organId: null, //机构id
		}
	},
	mounted() {
		this.getList()
	},
	methods: {
		handleSizeChange(pageSize) {
			this.tableParams.pageSize = pageSize
			this.doSearch()
		},
		handleCurrentChange(pageNum) {
			this.tableParams.pageNum = pageNum
			this.getList()
		},
		doSearch() {
			this.tableParams.pageNum = 1
			this.getList()
		},
		getList() {
			this.loading = true
			organPage({ ...this.tableParams, ...this.searchForm })
				.then((res) => {
					this.tableData = res.data.records
					this.total = res.data.total
					this.loading = false
				})
				.catch(() => {
					this.tableData = []
					this.total = 0
					this.loading = false
				})
		},
		// 查看人员
		seePeople(row) {
			this.organId = row.id
			this.dialogVisible = true
		},
	},
}
</script>

<style></style>
